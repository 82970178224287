import axios from 'axios';

const apiService = axios.create({
    baseURL: 'https://dev.credmudra.com/apis',
    headers: {
        'Content-Type': 'application/json'
    }
});

// Function to refresh the access token
const refreshAccessToken = async () => {
    const refreshToken = localStorage.getItem('refreshToken');
    const response = await axios.post('https://dev.credmudra.com/apis/public/refresh-token', {
        refreshToken
    });
    return response.data?.data?.token?.accessToken;
};

// Request interceptor
apiService.interceptors.request.use(
    (config) => {
        const accessToken = localStorage.getItem('accessToken');
        if (accessToken) {
            config.headers.Authorization = `Bearer ${accessToken}`;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

// Response interceptor
let isRefreshing = false;
let refreshSubscribers = [];

const onRefreshed = (accessToken) => {
    refreshSubscribers.forEach((callback) => callback(accessToken));
    refreshSubscribers = [];
};

apiService.interceptors.response.use(
    (response) => {
        return response;
    },
    (error) => {
        const originalRequest = error.config;

        if (error.response && error.response.status === 401 && !originalRequest._retry) {
            if (isRefreshing) {
                return new Promise((resolve) => {
                    refreshSubscribers.push((accessToken) => {
                        originalRequest.headers.Authorization = `Bearer ${accessToken}`;
                        resolve(apiService(originalRequest));
                    });
                });
            }

            originalRequest._retry = true;
            isRefreshing = true;

            return new Promise((resolve, reject) => {
                refreshAccessToken()
                    .then((accessToken) => {
                        localStorage.setItem('accessToken', accessToken);
                        apiService.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;
                        originalRequest.headers.Authorization = `Bearer ${accessToken}`;
                        onRefreshed(accessToken);
                        resolve(apiService(originalRequest));
                    })
                    .catch((error) => {
                        // Handle error during token refresh
                        reject(error);
                    })
                    .finally(() => {
                        isRefreshing = false;
                    });
            });
        }

        return Promise.reject(error);
    }
);

export default apiService;
