export const initialStateJourneyReducer = {
    rowData: {
        edges: [],
        nodes: [],
        formData: {}
    },
    journeyName: '',
    status:'active',
    openDrawer: false,
    selectedNodeType: '',
    id: '',
    selectedNodeId: null

}

const journeyCanvas = (state = initialStateJourneyReducer, action) => {
    switch (action.type) {
        case 'SET_JOURNEY':
            return {
                ...state,
                ...action.payload
            };
        case 'SET_JOURNEY_NAME':
            return {
                ...state,
                journeyName: action.payload
            };
        case 'SET_DRAWER_OPEN':
            return {
                ...state,
                openDrawer: action.payload
            };
        case 'SET_SELECTED_NODE_TYPE':
            return {
                ...state,
                selectedNodeType: action.payload
            };
        case 'SET_SELECTED_NODE_ID':
            return {
                ...state,
                selectedNodeId: action.payload
            };
        case 'SET_FORM_DATA':
            const fieldDetails = action.payload
            const cloneState = { ...state }
            cloneState['rowData']['formData'][cloneState.selectedNodeId] = fieldDetails
            return cloneState;

        default:
            return state;
    }
};

export default journeyCanvas;