import { useDispatch } from "react-redux"
import { Handle, Position } from "reactflow"
import { setDrawerOpen, setSelectedNodeType } from "store/action/journeyCanvas"
import Icon from '@mdi/react';
import { mdiExitToApp } from '@mdi/js';


export const ManavNode = () => {
    const dispatch = useDispatch()
    const handleClick = () => {
        dispatch(setDrawerOpen(true))
        dispatch(setSelectedNodeType('ManavNode'))
    }
    return <>
    <div onClick={handleClick} className="node_design_wrapper">
        <div className="icon_design"><Icon path={mdiExitToApp} size={1.7} /></div>
        <div className="node_info">
            <div className="NodeTitle">Event</div>
            <p className="NodeDesc">Event/Exit/Is_Segment</p>
        </div>
        <Handle type="target" position={Position.Top} id="3" />
        <Handle type="source" position={Position.Bottom} id="4" />
    </div>
    </>



    // return <div onClick={handleClick} className="node_container">
    //     <div className="NodeContent">
    //         <div className="NodeTitle">Event</div>
    //         <p className="NodeDesc">Event/Exit/Is_Segment</p>
    //     </div>
    //     <Handle type="target" position={Position.Bottom} id="5" />
    //     <Handle type="source" position={Position.Top} id="6" />
    // </div>
}