import * as React from 'react';
import { styled, useTheme } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import { useNavigate } from 'react-router';

import Icon from '@mdi/react';
import { mdiAccountOutline } from '@mdi/js';

import LogoSection from '../LogoSection';
import HeaderNav from './HeaderNav';
import { MyContext } from 'context/MyContextProvider';
import { useContext } from 'react';

// ==============================|| MAIN NAVBAR / HEADER ||============================== //

const Header = () => {
    const theme = useTheme();
    const navigate = useNavigate();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const { user, setUser } = useContext(MyContext);
    const handleMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };
    const handleLogout = () => {
        localStorage.setItem('accessToken', null);
        navigate('/login');
    };

    return (
        <>
            <Box sx={{ flexGrow: 1 }}>
                <AppBar position="static" color="primary">
                    <Toolbar disableGutters>
                        <Button
                            variant="text"
                            sx={{ width: '40px' }}
                            onClick={() => {
                                navigate('/');
                                // handleCloseDataMenu();
                            }}
                        >
                            <LogoSection />
                        </Button>
                        <Box sx={{ flexGrow: 1 }}>
                            <HeaderNav />
                        </Box>
                        <IconButton onClick={handleMenu}>
                            <Icon path={mdiAccountOutline} size={1} color="#fff" />
                        </IconButton>
                        <Menu
                            id="menu-appbar"
                            anchorEl={anchorEl}
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'right'
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right'
                            }}
                            open={Boolean(anchorEl)}
                            onClose={handleClose}
                        >
                            {/* <MenuItem onClick={handleClose}>Profile</MenuItem> */}
                            <MenuItem onClick={handleLogout}>Logout</MenuItem>
                        </Menu>
                    </Toolbar>
                </AppBar>
            </Box>
        </>
    );
};

export default Header;
