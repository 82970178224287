import React from "react";

export const NodeInfo = (nodeType) => {
  switch (nodeType) {
    case "send_email":
      return {
        title: "Email",
        description: "Description for Email",
      };
    case "begin_journey":
      return {
        title: "begin_journey",
        description: "Description for SMS",
      };
      case "end_journey":
        return {
          title: "End Journey",
          description: "Description for SMS",
        };
        case "contact_enters_journey":
          return {
            title: "contact_enters_journey",
            description: "Description for contact_enters_journey",
          };
    case "event_occurs":
      return {
        title: "event_occurs",
        description: "Description for Push",
      };
    case "mail_template":
      return {
        title: "mail_template",
        description: "Description for Email Mobile",
      };
    case "call_api_webhook":
      return {
        title: "call_api_webhook",
        description: "Description for Email Mobile",
      };
    case "add_to_segment":
      return {
        title: "add_to_segment",
        description: "Description for Email Mobile",
      };
    case "add_data_to_field":
      return {
        title: "add_data_to_field",
        description: "Description for Email Mobile",
      };
    case "is_in":
      return {
        title: "is_in",
        description: "Description for Email Mobile",
      };
    case "has_done":
      return {
        title: "has_done",
        description: "Description for Email Mobile",
      };
    case "user_attributes":
      return {
        title: "user_attributes",
        description: "Description for Email Mobile",
      };
    case "if_this_happens":
      return {
        title: "if_this_happens",
        description: "Description for Email Mobile",
      };
    case "wait_for":
      return {
        title: "wait_for",
        description: "Description for Email Mobile",
      };
    case "wait_for_time_slot":
      return {
        title: "wait_for_time_slot",
        description: "Description for Email Mobile",
      };
    case "wait_for_event":
      return {
        title: "wait_for_event",
        description: "Description for Email Mobile",
      };
    case "wait_for_date":
      return {
        title: "wait_for_date",
        description: "Description for Email Mobile",
      };
    case "split":
      return {
        title: "split",
        description: "Description for Email Mobile",
      };

    // Add more cases for other node types as needed
    default:
      return {
        title: "Default Title",
        description: "Default Description",
      };
  }
};
