export const selectGetEdges = (state) => {
    return state.journeyCanvas?.rowData?.edges
}
export const selectGetNodes = (state) => {
    return state.journeyCanvas?.rowData?.nodes
}

export const selectGetJourneyName = (state) => {
    return state.journeyCanvas?.journeyName
}
export const selectGetDrawer = (state) => {
    return state.journeyCanvas?.openDrawer
}
export const selectSelectedNodeType = (state) => {
    return state.journeyCanvas?.selectedNodeType
}
export const selectJourneyId = (state) => {
    return state.journeyCanvas?.id
}

export const selectGetFormData = (state) => {
    return state.journeyCanvas?.rowData.formData[state.journeyCanvas.selectedNodeId]
}
export const selectGetAllFormData = (state) => {
    return state.journeyCanvas?.rowData.formData
} 
