import { ManavNode } from "views/email-flow/Nodes/ManavNode";
import * as Node from "./Nodes";
import NodeSubmitForm from "./NodeSubmitForm";
import { VivekNode } from "views/email-flow/Nodes/VivekNode";
import { EmailNode } from "views/email-flow/Nodes/EmailNode";
import { NODETYPE } from "constant";
export const nodeTypes = {
  source: Node.Source,
  [NODETYPE.sendEmail]: EmailNode,
  send_email: Node.Action,
  begin_journey: Node.Source,
  end_journey: Node.End,
  contact_enters_journey: Node.Action,
  event_occurs: Node.Action,
  mail_template: Node.Action,
  call_api_webhook: Node.Action,
  add_to_segment: Node.Action,
  add_data_to_field: Node.Action,
  is_in: Node.Action,
  has_done: Node.Action,
  user_attributes: Node.Action,
  if_this_happens: Node.Action,
  wait_for: Node.Action,
  wait_for_time_slot: Node.Action,
  wait_for_event: Node.Action,
  wait_for_date: Node.Action,
  split: Node.Action,
  end: Node.End,
  occurrence_of_event: VivekNode,
  is_segment: ManavNode,
};
