import React, { useCallback, useEffect, useRef, useState } from 'react';
import ReactFlow, {
    Controls,
    MiniMap,
    ReactFlowProvider,
    useEdgesState,
    useNodesState,
    useReactFlow,
    addEdge,
    Background
} from 'reactflow';
import MyForm1 from './Form/Form1';
import EmailForm from './Form/EmailForm';
import axios from 'axios';
import './Automation.css';
import { nodeTypes } from './Nodes/index.jsx';
import { edgeTypes } from './Edges/index.jsx';
import { useLocation } from 'react-router-dom';
import { Box, Button, Grid, Input } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useDispatch, useSelector } from 'react-redux';
import { selectGetAllFormData, selectGetEdges, selectGetJourneyName, selectGetNodes, selectJourneyId } from 'store/selectors/index.js';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import AlignHorizontalCenterIcon from '@mui/icons-material/AlignHorizontalCenter';
import AlignVerticalCenterIcon from '@mui/icons-material/AlignVerticalCenter';
import ArrowCircleLeftOutlinedIcon from '@mui/icons-material/ArrowCircleLeftOutlined';
import { setJourneyName } from 'store/action/journeyCanvas';
import { useNavigate } from "react-router-dom";
import { baseURL } from 'config/envConfig';
import { useNodeInfo } from 'hooks/useNodeInfo';

export const Automation = (props) => {
    console.log("props", props)
    // const [nodeData] = useState({ title: 'Email', description: 'Send message to contacts.' });
    const location = useLocation(); // Retrieve the location object
    const { enqueueSnackbar } = useSnackbar();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const navigateToListFlow = () => {
        navigate("/");
    };
    const flowKey = 'cl-flow';
    const reactFlowWrapper = useRef(null);
    const initNodes = useSelector(selectGetNodes);
    const initEdges = useSelector(selectGetEdges);
    const journeyName = useSelector(selectGetJourneyName);
    const journeyId = useSelector(selectJourneyId);
    const [nodes, setNodes, onNodesChange] = useNodesState(initNodes);
    const [edges, setEdges, onEdgesChange] = useEdgesState(initEdges);
    const [reactFlowInstance, setReactFlowInstance] = useState(null);
    const { setViewport } = useReactFlow();
    const [selectedFormComponent, setSelectedFormComponent] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const formData = useSelector(selectGetAllFormData)


    const onSave = async (event) => {
        // console.log("hi")
        event.preventDefault();
        if (reactFlowInstance) {
            const rowData = reactFlowInstance.toObject();
            localStorage.setItem(flowKey, JSON.stringify(rowData));
            console.log(JSON.stringify(rowData));
        }
        let url = `${baseURL}/campaignJourney/`;
        if (journeyId.trim().length) {
            url += journeyId;
        }
        if (!journeyName.trim().length) {
            enqueueSnackbar('Provide Journey Name', { variant: 'error', anchorOrigin: { horizontal: 'right', vertical: 'top' } });
            return;
        }

        const flowdata = {
            journeyName,
            status: 'active',
            rowData: {
                nodes,
                edges,
                formData
            }
        };
        try {
            const response = journeyId.trim().length ? await axios.put(url, flowdata) : await axios.post(url, flowdata);
            console.log('API response:', response);
            enqueueSnackbar('Campaign Journey Saved !!!', { variant: 'success' });

            // remove code after demo
            let emailUrl = `${baseURL}/sendBulkEmail/`;
            if (formData?.dndnode_1?.offerId) {
                const emaildata = {
                    listId: formData?.dndnode_1?.offerId,
                    journeyId: "",
                }
                await axios.post(emailUrl, emaildata)
            }
            // remove code after demo
            // You can handle the response here
        } catch (error) {
            console.error('API error:', error);
            // Handle error here
        }
    };
    const onRestore = useCallback(() => {
        const restoreFlow = async () => {
            const flow = JSON.parse(localStorage.getItem(flowKey));
            if (flow) {
                const { x = 0, y = 0, zoom = 1 } = flow.viewport;
                setNodes(flow.nodes || []);
                setEdges(flow.edges || []);
                setViewport({ x, y, zoom });
                //2
            }
        };

        restoreFlow();
    }, [setEdges, setNodes, setViewport]);


    const onConnect = useCallback((params) => setEdges((eds) => addEdge(params, eds)), []);

    let id = 0;
    const getId = () => `dndnode_${id++}`;

    const onDrop = useCallback(
        (event) => {

            event.preventDefault();
            console.log('drag', event);
            const reactFlowBounds = reactFlowWrapper.current.getBoundingClientRect();
            const type = event.dataTransfer.getData('application/reactflow');



            // check if the dropped element is valid
            if (typeof type === 'undefined' || !type) {
                return;
            }

            const position = reactFlowInstance.project({
                x: event.clientX - reactFlowBounds.left,
                y: event.clientY - reactFlowBounds.top
            });
            // eslint-disable-next-line react-hooks/rules-of-hooks
            const { title, description } = useNodeInfo(type);
            const newNode = {
                id: getId(),
                type,
                position,
                data: {
                    title, // Set the title dynamically
                    description, // Set the description dynamically

                    stats: {
                        started: 0
                    }
                }
            };

            setNodes((nds) => nds.concat(newNode));
        },
        [reactFlowInstance]
    );

    const onDragOver = useCallback((event) => {
        event.preventDefault();
        event.dataTransfer.dropEffect = 'move';
    }, []);
    // =================================>

    return (
        <>
            <Box className="journey_appbar_container">
                <Box className="journey_appbar">
                    <Grid container>
                        <Grid className="journey_appbar_backButton" item >
                            <Button onClick={navigateToListFlow} sx={{ height: '100%' }}>
                                <ArrowCircleLeftOutlinedIcon />
                            </Button>
                        </Grid>
                        <Box variant='div' className="journey_appbar_journey">
                            <Grid item sx={{ flexGrow: 1 }}>
                                <Input
                                    placeholder="Journey name"
                                    className="custom-input" // Add a custom class name
                                    disableUnderline // Disable the underline
                                    variant="outlined"
                                    value={journeyName}
                                    onChange={(e) => {
                                        dispatch(setJourneyName(e.target.value));
                                    }}
                                />
                            </Grid>
                            <Grid item>
                                <Button className="journey_appbar_draft">SAVE DRAFT</Button> &nbsp; &nbsp;
                                <Button className="journey_appbar_publish" onClick={onSave}>
                                    PUBLISH NOW
                                </Button>
                            </Grid>
                        </Box>
                    </Grid>
                </Box>
                <Box className="journeysub_appbar">
                    <Grid
                        sx={{
                            display: 'flex',
                            // justifyContent: 'center',
                            alignItems: 'center'
                        }}
                        container
                    >
                        <Grid className="journeysub_appbar_addButton" item >
                            <Button>
                                <ExitToAppIcon />
                                &nbsp; Add Exit Trigger
                            </Button>
                        </Grid>
                        <Grid className="journeysub_appbar_addButton" item sx={{ flexGrow: 1 }}>
                            <Button>Set Conversion tracking</Button>
                        </Grid>
                        {/* <Grid
                            className="journeysub_appbar_addButton"
                            // sx={{ borderRight: '1px solid rgb(184, 184, 184)' }}
                            item
                            sx={{ flexGrow: 1 }}
                        >
                            <Button>Frequency Capping and DND</Button>
                        </Grid> */}
                        <Grid item xs={12} sm={5}>
                            <Box className="journeysub_appbar_icons">
                                <Grid className="journeysub_appbar_iconsCricle">
                                    <CameraAltIcon />
                                </Grid>
                                <Grid className="journeysub_appbar_iconsCricle">
                                    <AlignHorizontalCenterIcon />
                                </Grid>
                                <Grid className="journeysub_appbar_iconsCricle">
                                    <AlignVerticalCenterIcon />
                                </Grid>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
            <div className="AutomationCanvas">
                <ReactFlowProvider>
                    <div ref={reactFlowWrapper} className="reactflow-wrapper">
                        <ReactFlow
                            nodes={nodes}
                            edges={edges}
                            nodesConnectable={true}
                            nodeTypes={nodeTypes}
                            edgeTypes={edgeTypes}
                            // zoomOnScroll={false}
                            // zoomOnPinch={false}
                            panOnScroll
                            panOnDrag
                            preventScrolling
                            onConnect={onConnect}
                            // fitView
                            onInit={setReactFlowInstance}
                            onDrop={onDrop}
                            onDragOver={onDragOver}
                            onNodesChange={onNodesChange}
                            onEdgesChange={onEdgesChange}
                        // onNodeClick={onNodeClick}
                        >
                            <Controls showInteractive={false} className="Controls" />
                            <MiniMap />
                            <Background variant="dots" gap={16} size={1} />
                        </ReactFlow>
                    </div>
                </ReactFlowProvider>
            </div>
        </>
    );
};

const Layout = (props) => (
    <ReactFlowProvider>
        <Automation {...props} />
    </ReactFlowProvider>
);

export default Layout;
