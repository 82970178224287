// reducers.js
import { combineReducers } from 'redux';
import journeyCanvas from './journeyCanvas';
import customizationReducer from '../customizationReducer'

const rootReducer = combineReducers({
    journeyCanvas,
    customization: customizationReducer
    // other reducers...
});

export default rootReducer;