import { lazy } from 'react';
// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';
// import ProtectedRoute from './ProtectedRoute';
// import EmailFlow from 'views/email-flow/EmailFlow';
import ReactEmailFlow from 'views/react-email-flow/ReactEmailFlow';
import { CampaignJourneyList } from 'views/campaign-journey-list/CampaignJourneyList';
import { CampaignJourneyDashboard } from 'views/campaign-journey-list/CampaignJourneyDashboard';

// utilities routing
const Dashboard = Loadable(lazy(() => import('views/dashboard')));

// sample page routing
const SamplePage = Loadable(lazy(() => import('views/sample-page')));

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
    path: '/',
    element: <MainLayout />,
    children: [
        {
            path: '/',
            element: (
                // <ProtectedRoute>
                // <CampaignJourneyList />
                <CampaignJourneyDashboard/>
                // </ProtectedRoute>
            )
        },
        {
            path: '/email-flow',
            element: (
                // <ProtectedRoute>
                <ReactEmailFlow />
                // </ProtectedRoute>
            )
        },
        {
            path: '/react-email-flow',
            element: (
                //  <ProtectedRoute>
                <ReactEmailFlow />
                //</ProtectedRoute>
            )
        },
    ]
};

export default MainRoutes;
