import Box from '@mui/material/Box';
import { TextField, Button, FormControlLabel, Checkbox } from '@mui/material';
import Typography from '@mui/joy/Typography';

export const BeginJourneyFormComponent = () => {
    return (
        <Box
            sx={{
                '& .MuiTextField-root': {
                    m: 1,
                    width: '350px'
                },
                margin: '24px'
            }}
            noValidate
            autoComplete="off"
        >
            <div>
                <Typography level="h4"> Begin Journey Form </Typography>
            </div>
            <div>
                <TextField id="filled-hidden-label-normal" label="Volume Cap" variant="standard" name="VolumeCap" />
            </div>
            <div>
                <TextField id="filled-hidden-label-normal" label="ISP Cap" variant="standard" name="ISPCap" />
            </div>
            <Box sx={{ padding: '0 8px' }}>
                <div>
                    <FormControlLabel control={<Checkbox id="Bouncecheck" name="Bouncecheck" />} label="Bounce check" />
                </div>
                <div>
                    <FormControlLabel control={<Checkbox id="LogCheck" name="LogCheck" />} label="Log Check" />
                </div>
            </Box>

            <div>
                {/* <Box
                    sx={{
                        marginTop: 4,
                        padding: '0px 10px',
                        display: 'flex'
                    }}
                >
                    <Button variant="outlined">Save</Button>
                    &nbsp; &nbsp; &nbsp;
                    <Button variant="outlined">Reset</Button>
                </Box> */}
            </div>
        </Box>
    );
};

export default BeginJourneyFormComponent;
