import { Skeleton, TextField } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import IconButton from "@mui/material/IconButton";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import * as icons from "@mui/icons-material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { styled } from "@mui/material/styles";
import "./Sidebar.css";
import StarIcon from "@mui/icons-material/Star";
import { Box, height } from "@mui/system";
import Icon from "@mdi/react";
import { baseURL } from "config/envConfig";
import {
  mdiEmailOutline,

  mdiEmailVariant,

  mdiEmailFastOutline,

  mdiClockStart,
  mdiStopCircleOutline,
  mdiLocationEnter,
  mdiCalendarClock,
  mdiWebhook,
  mdiSegment,
  mdiPlus,
  mdiViewListOutline,
  mdiCheckOutline,
  mdiAccountCircleOutline,
  mdiShapeOutline,
  mdiTimerSandComplete,
  mdiCalendarFilterOutline,
  mdiClipboardTextClock,
  mdiFormatPageSplit,
} from "@mdi/js";

const onDragStart = (event, nodeType) => {
  // debugger;
  // event.dataTransfer.setData("application/reactflow", nodeType); // Set a custom dataTransfer type
  event.dataTransfer.setData("application/reactflow", nodeType); // Store the node type in a custom data attribute
  event.dataTransfer.effectAllowed = "move";
};
// const onDragStart = (event, nodeType) => {
//   event.dataTransfer.setData('application/reactflow', nodeType);
//   event.dataTransfer.effectAllowed = 'move';
// };

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === "dark"
      ? "rgba(255, 255, 255, .05)"
      : "rgba(0, 0, 0, .03)",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "1px solid rgba(0, 0, 0, .125)",
}));

const Sidebar = () => {
  const [expanded, setExpanded] = useState("panel1");
  const [loading, setLoading] = useState(true);

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : true);
  };

  const [data, setData] = useState([]);

  useEffect(() => {
    // Define the API URL you want to fetch data from
    // const apiUrl = 'https://fakestoreapi.com/products';

    const apiUrl = `${baseURL}/itemsData/`;
    // Make a GET request using Axios
    axios
      .get(apiUrl)
      .then((response) => {
        // Handle the successful response and update the state with data
        setData(response.data);
        // console.log("sidebar",response.data)
        setLoading(false);
      })
      .catch((error) => {
        // Handle errors if the request fails
        console.error("Error fetching data:", error);
      });
  }, []);

  function GetMaterialIcon({ icon }) {
    const IconComponent = icons[icon];
    return (
      <IconButton>
        <IconComponent />
      </IconButton>
    );
  }

  return (
    <>
      <div className="main">
        <div className="description">
          <h2 style={{ fontWeight: 500, fontSize: "16px" }}>Control Steps</h2>
        </div>

        {loading ? (
          <Box sx={{ width: 300, height: 500 }}>
            <Skeleton animation="wave" />
            <Skeleton animation="wave" />
            <Skeleton animation="wave" />
            <Skeleton animation="wave" />
            <Skeleton animation="wave" />
            <Skeleton animation="wave" />
            <Skeleton animation="wave" />
          </Box>
        ) : (
          // Render your actual content here once data is available
          <div>
            {data.map((item, index) => {
              return (
                <>
                  <Accordion
                    expandIcon={<ExpandMoreIcon />}
                    onChange={handleChange("panel1")}
                    key={index}
                  >
                    <AccordionSummary
                      className="sidebar_accordian_summary"
                      aria-controls="panel1d-content"
                      id="panel1d-header"
                      sx={{
                        backgroundColor: "#fff",
                      }}
                    >
                      <Typography key={index}>{item.title}</Typography>
                    </AccordionSummary>
                    <AccordionDetails className="sidebar_details">
                      {item?.items.map((item2, index2) => {
                        return (
                          <>
                            <div key={index2}>
                              <Box
                                className="details_icon_box1"
                                onDragStart={(event) =>
                                  onDragStart(event, item2.type)
                                }
                                draggable
                                key={item2.type}
                              >
                                <Box className="details_icon_box2">
                                  <Box className="details_icon_box3">
                                    {item2.type === "begin_journey" ? (
                                      <Icon
                                        path={mdiClockStart}
                                        size={1}
                                      />
                                    ) : item2.type === "event_occurs" ? (
                                      <Icon path={mdiCalendarClock} size={1} />
                                    ) : item2.type === "mail_template" ? (
                                      <Icon path={mdiEmailFastOutline} size={1} />
                                    ) : item2.type === "call_api_webhook" ? (
                                      <Icon
                                        path={mdiWebhook}
                                        size={1}
                                      />
                                    ) : item2.type === "add_to_segment" ? (
                                      <Icon path={mdiSegment} size={1} />
                                    ) : item2.type === "is_in" ? (
                                      <Icon path={mdiViewListOutline} size={1} />
                                    ) : item2.type === "has_done" ? (
                                      <Icon path={mdiCheckOutline} size={1} />
                                    ) : item2.type === "if_this_happens" ? (
                                      <Icon
                                        path={mdiShapeOutline}
                                        size={1}
                                      />
                                    ) : item2.type === "user_attributes" ? (
                                      <Icon path={mdiAccountCircleOutline} size={1} />
                                    ) : item2.type === "wait_for" ? (
                                      <Icon
                                        path={mdiCalendarClock}
                                        size={1}
                                      />
                                    ) : item2.type === "wait_for_time_slot" ? (
                                      <Icon
                                        path={mdiTimerSandComplete}
                                        size={1}
                                      />
                                    ) : item2.type === "wait_for_event" ? (
                                      <Icon path={mdiCalendarFilterOutline} size={1} />
                                    ) : item2.type === "wait_for_date" ? (
                                      <Icon path={mdiClipboardTextClock} size={1} />
                                    ) : item2.type === "split" ? (
                                      <Icon
                                        path={mdiFormatPageSplit}
                                        size={1}
                                      />
                                    ) : item2.type === "end_journey" ? (
                                      <Icon
                                        path={mdiStopCircleOutline}
                                        size={1}
                                      />
                                    ) : item2.type === "contact_enters_journey" ? (
                                      <Icon
                                        path={mdiLocationEnter}
                                        size={1}
                                      />
                                    ) : item2.type === "send_email" ? (
                                      <Icon
                                        path={mdiEmailOutline}
                                        size={1}
                                      />
                                    ) : item2.type === "add_data_to_field" ? (
                                      <Icon path={mdiPlus} size={1} />
                                    ) : null}
                                  </Box>
                                </Box>
                                <Box className="details_text_box">
                                  <Typography className="details_textbox_text">
                                    {item2.name}
                                  </Typography>
                                </Box>
                              </Box>
                            </div>
                          </>
                        );
                      })}

                      {/* <div className="container">
                    <Grid container spacing={0}>
                      {item?.items.map((item2, index2) => {
                        return (
                          <div className="icon-component" key={index2}>
                            <div
                              className="dndnode input"
                              onDragStart={(event) =>
                                onDragStart(event, item2.type)
                              }
                              draggable
                              key={item2.type}
                            >
                              <>
                                <GetMaterialIcon icon='ThreeDRotation' />
                              </>
                            </div>
                            <div style={{ display: "flex" }}>
                              <span className="textIcon">{item2.name}</span>
                            </div>
                          </div>
                        );
                      })}
                    </Grid>
                  </div> */}
                    </AccordionDetails>
                  </Accordion>
                </>
              );
            })}
          </div>
        )}
      </div>
    </>
  );
};

export default Sidebar;
