import React, { useContext, useState } from 'react';
import { MyContext } from '../../context/MyContextProvider';
import Avatar from '@mui/material/Avatar';
// import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import { Link } from 'react-router-dom';
import { height } from '@mui/system';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import api from '../../helpers/apiService';
import { useNavigate } from 'react-router-dom';
// import SnackbarAlert from 'ui-component/snackbar/SnackbarAlert';
import { SnackbarContext } from '../../context/SnackBarContext';
import { Snackbar } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';

const validationSchema = Yup.object({
    email: Yup.string().email('Invalid email').required('Email is required'),
    password: Yup.string().required('Password is required')
});

function Copyright(props) {
    return (
        <Typography variant="body2" color="text.secondary" align="center" {...props}>
            {'Copyright © '}
            MIS
        </Typography>
    );
}

const defaultTheme = createTheme();
const Login = () => {
    const navigate = useNavigate();
    const { user, setUser, accessToken, setAccessToken, refreshToken, setRefreshToken } = useContext(MyContext);
    const { setSnackbar } = useContext(SnackbarContext);
    const [open, setopen] = useState(false);
    const [message, setmessage] = useState('');
    const [loading, setLoading] = React.useState(false);
    const formik = useFormik({
        initialValues: {
            email: '',
            password: ''
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {
            setLoading(true);
            const data = {
                emailId: values.email,
                password: values.password
            };
            navigate('/dashboard');
            // api.post('/public/agent-login', { data })
            //     .then((response) => {
            //         // console.log(response.data.data);
            //         localStorage.setItem('accessToken', response.data.data.token.accessToken);
            //         localStorage.setItem('refressToken', response.data.data.token.accessToken);
            //         setUser(response.data.data.token);
            //         setRefreshToken(response?.data?.data?.token?.refreshToken);
            //         setAccessToken(response?.data?.data?.token?.accessToken);
            //         setUser(response.data.data.token);
            //         setLoading(false);
            //         setopen(true);
            //         setmessage('Login Successfully', 'success');
            //         navigate('/dashboard');
            //     })
            //     .catch((error) => {
            //         // console.log(error);
            //         setLoading(false);
            //         setopen(true);
            //         setmessage('Invalid email or password.', 'error');
            //     });
            // setSuccess(false);
        }
    });

    return (
        <>
            <ThemeProvider theme={defaultTheme}>
                <Container component="main" maxWidth="xs" sx={{ height: '100vh', display: 'flex', alignItems: 'center' }}>
                    <CssBaseline />
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center'
                        }}
                    >
                        <Avatar sx={{ m: 1, bgcolor: 'white' }}>
                            <LockOpenIcon color="primary" />
                        </Avatar>
                        <Typography component="h1" variant="h5">
                            LOGIN
                        </Typography>
                        <Box component="form" onSubmit={formik.handleSubmit} sx={{ mt: 3 }}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <TextField
                                        fullWidth
                                        id="email"
                                        name="email"
                                        label="Email"
                                        value={formik.values.email}
                                        onChange={formik.handleChange}
                                        error={formik.touched.email && formik.errors.email ? true : false}
                                        helperText={formik.touched.email && formik.errors.email}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        fullWidth
                                        id="password"
                                        name="password"
                                        label="Password"
                                        type="password"
                                        value={formik.values.password}
                                        onChange={formik.handleChange}
                                        error={formik.touched.password && formik.errors.password ? true : false}
                                        helperText={formik.touched.password && formik.errors.password}
                                    />
                                </Grid>
                            </Grid>
                            {/* <Grid item xs={12} p={1}>
                                <Link color="primary" to="/forgot-password">
                                    Forgot Password
                                </Link>
                            </Grid> */}
                            {/* <Button>login</Button> */}
                            <LoadingButton
                                type="submit"
                                variant="contained"
                                fullWidth
                                sx={{ mt: 1, mb: 2, p: 2 }}
                                loading={loading}
                                loadingPosition="start"
                            >
                                login
                            </LoadingButton>
                        </Box>
                        <Copyright sx={{ mt: 5 }} />
                    </Box>
                    {/* <SnackbarAlert message={message} /> */}
                    <Snackbar open={open} message={message} />
                </Container>
            </ThemeProvider>
        </>
    );
};
export default Login;
