export const setJourneyObject = (payload) => ({
    type: 'SET_JOURNEY',
    payload
});
export const setJourneyName = (payload) => ({
    type: 'SET_JOURNEY_NAME',
    payload
});
export const setDrawerOpen = (payload) => ({
    type: 'SET_DRAWER_OPEN',
    payload
})
export const setSelectedNodeType = (payload) => ({
    type: 'SET_SELECTED_NODE_TYPE',
    payload
})
export const setSelectedNodeId = (payload) => ({
    type: 'SET_SELECTED_NODE_ID',
    payload
})
export const setFormData = (payload) => ({
    type: 'SET_FORM_DATA',
    payload
})
