import { Typography } from '@mui/joy';
import { Box, Checkbox, FormControl, FormControlLabel, InputLabel, MenuItem, Select } from '@mui/material';
import React from 'react';
import { useState } from 'react';

export const EventOccurs = () => {
    const [age, setAge] = useState('');

    const handleChange = (event) => {
        setAge(event.target.value);
    };
    return (
        <div>
            <Box
                sx={{
                    m: 1,
                    width: '350px',
                    margin: '24px'
                }}
                noValidate
                autoComplete="off"
            >
                <div>
                    <Typography level="h4"> Event Occurs Form </Typography>
                </div>
                <div>
                    <FormControl variant="standard" sx={{ m: 1, minWidth: '100%' }}>
                        <InputLabel id="filled-hidden-label-normal">Form is filled: </InputLabel>
                        <Select
                            labelId="demo-simple-select-standard-label"
                            id="demo-simple-select-standard"
                            value={age}
                            label="List"
                            onChange={handleChange}
                        >
                            <MenuItem value="">
                                <em>None</em>
                            </MenuItem>
                            <MenuItem value={10}>Ten</MenuItem>
                            <MenuItem value={20}>Twenty</MenuItem>
                            <MenuItem value={30}>Thirty</MenuItem>
                        </Select>
                    </FormControl>
                </div>
                <div>
                    <Box sx={{ padding: '0 8px' }}>
                        <div>
                            <FormControlLabel control={<Checkbox id="Bouncecheck" name="opener segment" />} label="User opens Mail " />
                        </div>
                        <div>
                            <FormControlLabel control={<Checkbox id="LogCheck" name="clicker segment" />} label="User clicks CTA" />
                        </div>
                        <div>
                            <FormControlLabel
                                control={<Checkbox id="LogCheck" name="clicker segment" />}
                                label="Their not conditions & time filters"
                            />
                        </div>
                    </Box>
                </div>
            </Box>
        </div>
    );
};
