import { useState } from "react";
import * as React from 'react';

import Box from '@mui/material/Box';
import { TextField, Button } from '@mui/material';
import { FormControl, FormLabel } from '@mui/material';
import { multiFormNodeContext } from "context/NodeFormContext";
import { useContext } from "react";
import { useNavigate,useLocation } from 'react-router-dom';


function MyForm1({stateData}) {
  const {setCurrentFormNode, formDataFlow, setFormDataFlow} = useContext(multiFormNodeContext);
  // console.log(formDataFlow);
  const [open, setOpen] = React.useState(false);


    // const [formData, setFormData] = useState({
    //   // id:"",
    //   journeyName: "",
    //   description:""
    // });
  
    // const handleInputChange = (event) => {
    //   const { name, value } = event.target;
    //   setFormData((formData) => ({ ...formData, [name]: value }));
    // };

    const handleSubmit = async (event) => {
      event.preventDefault();
      console.log(event)
      try {
        const response = await fetch('https://api.flow.campaignlabs.io/apis/v1/campaignJourney', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          // body: JSON.stringify(formData),
          
        });
  
        if (response.ok) {
          console.log('Form data submitted successfully');
          // Handle success behavior
        } else {
          console.error('Error submitting form data');
          // Handle error behavior
        }
      } catch (error) {
        console.error('Error:', error);
        // Handle error behavior
      }
    };
  


  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    right: '0%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    hright:"auto",
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    Float:"right",
    p: 4,
  };


  
  const [name, setName] = useState("");
  return (
    <Box >

<form onSubmit={handleSubmit}>
    <FormControl sx={style}>
        <FormLabel>Journey Name </FormLabel>
        <TextField name="journeyName" value={stateData?.journeyName} onChange={(e)=>setFormDataFlow({...formDataFlow, "journeyName":e.target.value})}  fullWidth></TextField>
        <FormLabel >Description</FormLabel>
        <TextField name="description" value={stateData?.description} onChange={(e)=>setFormDataFlow({...formDataFlow, "description":e.target.value})} fullWidth></TextField>
        {/* <Button type="submit">Save</Button> */}
    </FormControl>

{/* <FormControl sx={style}>
        <FormLabel>Journey Name </FormLabel>
        <TextField name="journeyName" value={formData.journeyName} onChange={handleInputChange} fullWidth></TextField>
        <FormLabel >Description</FormLabel>
        <TextField name="description" value={formData.field1} onChange={handleInputChange} fullWidth></TextField>
        <Button type="submit">Submit</Button>
    </FormControl>     */}
    </form>
    </Box>

  )
}



export default MyForm1
