import { useDispatch } from "react-redux"
import { Handle, Position, useNodeId } from "reactflow";
import { setDrawerOpen, setSelectedNodeId, setSelectedNodeType } from "store/action/journeyCanvas"
import Icon from '@mdi/react';
import { mdiEmailOutline } from '@mdi/js';

export const EmailNode = () => {
    const nodeId = useNodeId();
    const dispatch = useDispatch();
    console.log('dispatch--',dispatch);
    const handleClick = () => {
        dispatch(setDrawerOpen(true))
        dispatch(setSelectedNodeType('send_email'))
        dispatch(setSelectedNodeId(nodeId))
    }

    return <>
    <div onClick={handleClick} className="node_design_wrapper">
        <div className="icon_design"><Icon path={mdiEmailOutline} size={1.7} /></div>
        <div className="node_info">
            <div className="NodeTitle">Email</div>
            <p className="NodeDesc">Email Related Content</p>
        </div>
        <Handle type="target" position={Position.Top} id="5" />
        <Handle type="source" position={Position.Bottom} id="6" />
    </div>
    </>
}