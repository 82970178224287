import { styled } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { useNavigate } from 'react-router-dom';
import { mdiClockOutline } from '@mdi/js';
import Icon from '@mdi/react';
import { Box, Button, CircularProgress, Tooltip, tooltipClasses } from '@mui/material';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { baseURL } from 'config/envConfig';
import Grid from '@mui/material/Grid';
import { mdiRun } from '@mdi/js';
import { mdiFileEditOutline } from '@mdi/js';
import { mdiDeleteAlertOutline } from '@mdi/js';
import './Cards.css';
import { useDispatch } from 'react-redux';
import { setJourneyObject } from 'store/action/journeyCanvas';
import { useSnackbar } from 'notistack';
import { initialStateJourneyReducer } from 'store/reducer/journeyCanvas';
const LightTooltip = styled(({ className, ...props }) => <Tooltip {...props} classes={{ popper: className }} />)(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
    fontSize: 11
  }
}));

export const CampaignJourneyDashboard = () => {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const navigateToReactFlow = () => {
    // 👇️ navigate to /contacts
    dispatch(setJourneyObject(initialStateJourneyReducer));
    navigate('/react-email-flow');
  };

  const [data, setData] = useState([]);
  const [Loading, setLoading] = useState(true);
  const getListData = async function () {
    const apiUrl = `${baseURL}/campaignJourney`;

    axios
      .get(apiUrl)
      .then((response) => {
        // Assuming the array you want to map is in the 'data' property of the response
        const responseData = response.data;
        if (responseData.length) {
          setData(responseData);
        }
        setLoading(false);
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });
  };
  useEffect(() => {
    getListData();
  }, []);

  const handleDeleteIconClick = async (id) => {
    const url = `${baseURL}/campaignJourney/${id}`;
    try {
      const response = await axios.delete(url);
      console.log('API response:', response.data);
      enqueueSnackbar('Deleted Successfully', { variant: 'success' });
      getListData();
      // You can handle the response here
    } catch (error) {
      console.error('API error:', error);
      // Handle error here
    }
  };

  const handleIconClick = async () => {
    const url = `${baseURL}/sendBulkEmail`;
    const data = {
      to: 'hemlata@zappian.com',
      subject: 'Test Email from Journey Builder',
      text: 'Hello Varun This email comes from Journey Builder to inform you journey builder is on air '
    };

    try {
      const response = await axios.post(url, data);
      console.log('API response:', response.data);
      enqueueSnackbar('Email sent successfully', { variant: 'success' });
      // You can handle the response here
    } catch (error) {
      console.error('API error:', error);
      // Handle error here
    }
  };

  const navigateToFlow = (campaignObj) => {
    dispatch(setJourneyObject(campaignObj));

    navigate('/email-flow');
  };

  return (
    <React.Fragment>
      <Box
        className="mainBox"
        sx={{
          bgcolor: '#F2F4F8',
          width: '100%',
          height: '647px',
          paddingTop: '-4px',
          paddingLeft: '20px'
        }}
      >
        <Box
          sx={{
            height: '88px',
            width: '100%',
            bgcolor: '#F2F4F8',
            display: 'flex',
            alignItems: 'center'
          }}
        >
          <Typography
            sx={{
              color: '#000',
              width: '100%',
              leadingTrim: 'both',
              textEdge: 'cap',
              fontFamily: 'Inter',
              fontSize: '20px',
              fontStyle: 'normal',
              fontWeight: 500,
              lineHeight: 'normal',
              letterSpacing: '-0.48px'
            }}
          >
            Campaign Journey
          </Typography>
          {/* <link  to="/react-email-flow"> */}
          <Button
            variant="contained"
            onClick={navigateToReactFlow}
            sx={{
              borderRadius: '22px',
              whiteSpace: 'nowrap',
              width: '150px',
              padding: ' 10px 10px ',
              marginRight: '25px',
              color: 'white'
            }}
          >
            Create Journey
          </Button>
        </Box>
        <Box>
          <Grid container spacing={2}>
            {Loading ? (
              <Box
                sx={{
                  width: '100vw',
                  marginTop: 5,
                  flexWrap: 'wrap',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-evenly'
                }}
              >
                <div id="container">
                  <div id="square" class="shimmer"></div>
                  <div id="content">
                    <div id="content-title" class="shimmer"></div>
                    <div id="content-desc">
                      <div class="line shimmer"></div>
                      <div class="line shimmer"></div>
                      <div class="line shimmer"></div>
                      <div class="line shimmer"></div>
                    </div>
                  </div>
                </div>
                <div id="container">
                  <div id="square" class="shimmer"></div>
                  <div id="content">
                    <div id="content-title" class="shimmer"></div>
                    <div id="content-desc">
                      <div class="line shimmer"></div>
                      <div class="line shimmer"></div>
                      <div class="line shimmer"></div>
                      <div class="line shimmer"></div>
                    </div>
                  </div>
                </div>
                <div id="container">
                  <div id="square" class="shimmer"></div>
                  <div id="content">
                    <div id="content-title" class="shimmer"></div>
                    <div id="content-desc">
                      <div class="line shimmer"></div>
                      <div class="line shimmer"></div>
                      <div class="line shimmer"></div>
                      <div class="line shimmer"></div>
                    </div>
                  </div>
                </div>
                <div id="container">
                  <div id="square" class="shimmer"></div>
                  <div id="content">
                    <div id="content-title" class="shimmer"></div>
                    <div id="content-desc">
                      <div class="line shimmer"></div>
                      <div class="line shimmer"></div>
                      <div class="line shimmer"></div>
                      <div class="line shimmer"></div>
                    </div>
                  </div>
                </div>
                <div id="container">
                  <div id="square" class="shimmer"></div>
                  <div id="content">
                    <div id="content-title" class="shimmer"></div>
                    <div id="content-desc">
                      <div class="line shimmer"></div>
                      <div class="line shimmer"></div>
                      <div class="line shimmer"></div>
                      <div class="line shimmer"></div>
                    </div>
                  </div>
                </div>
                <div id="container">
                  <div id="square" class="shimmer"></div>
                  <div id="content">
                    <div id="content-title" class="shimmer"></div>
                    <div id="content-desc">
                      <div class="line shimmer"></div>
                      <div class="line shimmer"></div>
                      <div class="line shimmer"></div>
                      <div class="line shimmer"></div>
                    </div>
                  </div>
                </div>
                <div id="container">
                  <div id="square" class="shimmer"></div>
                  <div id="content">
                    <div id="content-title" class="shimmer"></div>
                    <div id="content-desc">
                      <div class="line shimmer"></div>
                      <div class="line shimmer"></div>
                      <div class="line shimmer"></div>
                      <div class="line shimmer"></div>
                    </div>
                  </div>
                </div>
                <div id="container">
                  <div id="square" class="shimmer"></div>
                  <div id="content">
                    <div id="content-title" class="shimmer"></div>
                    <div id="content-desc">
                      <div class="line shimmer"></div>
                      <div class="line shimmer"></div>
                      <div class="line shimmer"></div>
                      <div class="line shimmer"></div>
                    </div>
                  </div>
                </div>
                <div id="container">
                  <div id="square" class="shimmer"></div>
                  <div id="content">
                    <div id="content-title" class="shimmer"></div>
                    <div id="content-desc">
                      <div class="line shimmer"></div>
                      <div class="line shimmer"></div>
                      <div class="line shimmer"></div>
                      <div class="line shimmer"></div>
                    </div>
                  </div>
                </div>
                <div id="container">
                  <div id="square" class="shimmer"></div>
                  <div id="content">
                    <div id="content-title" class="shimmer"></div>
                    <div id="content-desc">
                      <div class="line shimmer"></div>
                      <div class="line shimmer"></div>
                      <div class="line shimmer"></div>
                      <div class="line shimmer"></div>
                    </div>
                  </div>
                </div>
                <div id="container">
                  <div id="square" class="shimmer"></div>
                  <div id="content">
                    <div id="content-title" class="shimmer"></div>
                    <div id="content-desc">
                      <div class="line shimmer"></div>
                      <div class="line shimmer"></div>
                      <div class="line shimmer"></div>
                      <div class="line shimmer"></div>
                    </div>
                  </div>
                </div>
                <div id="container">
                  <div id="square" class="shimmer"></div>
                  <div id="content">
                    <div id="content-title" class="shimmer"></div>
                    <div id="content-desc">
                      <div class="line shimmer"></div>
                      <div class="line shimmer"></div>
                      <div class="line shimmer"></div>
                      <div class="line shimmer"></div>
                    </div>
                  </div>
                </div>
              </Box>
            ) : (
              <>
                {data.map((item, index) => (
                  <Grid item xs={2} key={index}>
                    <Card sx={{ borderRadius: '3px', cursor: 'pointer', border: '1px solid #d5d5d5', boxShadow: '3px 5px 8px 1px #dadada' }}>
                      <CardHeader
                        sx={{ textOverflow: 'ellipsis' }}
                        avatar={
                          <div
                            className="square"
                            style={{
                              background:
                                item.status === 'active'
                                  ? 'green'
                                  : item.status === 'inactive'
                                    ? 'red'
                                    : item.status === 'draft'
                                      ? 'gray'
                                      : 'transparent',
                              width: '14px',
                              height: '14px',
                              borderRadius: '50em'
                            }}
                          ></div>
                        }
                        titleTypographyProps={{ variant: 'h1' }}
                        title={item.journeyName}
                      //   subheader="September 14, 2016"
                      />
                      <CardContent sx={{ paddingTop: '0px' }}>
                        <Typography
                          variant="body2"
                          color="text.secondary"
                          sx={{
                            textOverflow: 'ellipsis',
                            overflow: 'hidden',
                            whiteSpace: 'nowrap',
                            textAlign: 'justify'
                          }}
                        >
                          {item.description}
                        </Typography>
                      </CardContent>
                      <CardActions disableSpacing sx={{ padding: '0 24px' }}>
                        <LightTooltip title="Schedule">
                          <Icon path={mdiClockOutline} size={1} />
                        </LightTooltip>
                        <LightTooltip title="Run">
                          <IconButton
                            aria-label="share"
                          // onClick={() => {
                          //   handleIconClick();
                          // }}
                          >
                            <Icon path={mdiRun} size={1} />
                          </IconButton>
                        </LightTooltip>
                        <LightTooltip title="Edit">
                          <IconButton
                            aria-label="share"
                            onClick={() => {
                              navigateToFlow(item);
                            }}
                          >
                            <Icon path={mdiFileEditOutline} size={1} />
                          </IconButton>
                        </LightTooltip>
                        <LightTooltip title="Delete">
                          <IconButton aria-label="share" onClick={() => handleDeleteIconClick(item.id)}>
                            <Icon path={mdiDeleteAlertOutline} size={1} />
                          </IconButton>
                        </LightTooltip>
                      </CardActions>
                    </Card>
                  </Grid>
                ))}
              </>
            )}
          </Grid>
        </Box>
      </Box>
    </React.Fragment>
  );
};
