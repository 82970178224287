import * as React from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import Icon from '@mdi/react';
import { mdiViewDashboard } from '@mdi/js';
import { mdiDatabase } from '@mdi/js';
import { mdiImport } from '@mdi/js';
import { mdiFileDocumentOutline } from '@mdi/js';
import { mdiDatabaseCogOutline } from '@mdi/js';
import { mdiDatabaseArrowRightOutline } from '@mdi/js';
import { mdiChartArc } from '@mdi/js';
import { useNavigate, useParams } from 'react-router';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

const dataLabsNavigation = [
    {
        title: 'Campaign Journey',
        icon: mdiViewDashboard,
        desc: 'Campaign Journey : Displaying Campaign Journey List in one place',
        route: '/'
    },
    // { title: 'Leads', icon: mdiViewDashboard, desc: 'A contact already determined to be a prospective customer', route: '/leads' },
    // { title: 'Email Flow', icon: mdiViewDashboard, desc: 'A contact already determined to be a prospective customer', route: '/email-flow' }
];

export default function HeaderNmainav() {
    const theme = useTheme();
    const navigate = useNavigate();

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleOpenDataMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleCloseDataMenu = () => {
        setAnchorEl(null);
    };

    const pageName = window.location.pathname === '/leads' ? 'Leads' : window.location.pathname === '/dashboard' ? 'Dashboard' : null;

    return (
        <div>
            <Button
                id="basic-button"
                aria-controls={open ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleOpenDataMenu}
                sx={{ color: '#fff' }}
                endIcon={<KeyboardArrowDownIcon />}
            >
                {pageName}
            </Button>
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleCloseDataMenu}
                MenuListProps={{
                    'aria-labelledby': 'basic-button'
                }}
            >
                {dataLabsNavigation.map((item, index) => (
                    <MenuItem
                        key={index}
                        onClick={() => {
                            navigate(item?.route);
                            handleCloseDataMenu();
                        }}
                    >
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            {/* <CardMedia sx={{ mr: 2 }} component="img" height="30" image={item?.icon} alt={item?.title} /> */}
                            <Box>
                                <Icon path={item?.icon} size={1} />
                            </Box>
                            <Box p={1}>
                                <Typography textAlign="left" variant="h4">
                                    {item.title}
                                </Typography>
                                <Typography textAlign="left" variant="p">
                                    {item.desc}
                                </Typography>
                            </Box>
                        </Box>
                    </MenuItem>
                ))}
            </Menu>
        </div>
    );
}
