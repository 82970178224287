// material-ui

// project imports
import Logo from 'ui-component/Logo';

// ==============================|| MAIN LOGO ||============================== //

const LogoSection = () => {
    return (
        <>
            <Logo />
        </>
    );
};

export default LogoSection;
