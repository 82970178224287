import { useDispatch } from "react-redux"
import { Handle, Position } from "reactflow"
import { setDrawerOpen, setSelectedNodeType } from "store/action/journeyCanvas"
import Icon from '@mdi/react';
import { mdiStarCircleOutline } from '@mdi/js';



export const VivekNode = () => {
    const dispatch = useDispatch()
    const handleClick = () => {
        dispatch(setDrawerOpen(true))
        dispatch(setSelectedNodeType('VivekNode'))
    }
    return <>
    <div onClick={handleClick} className="node_design_wrapper">
        <div className="icon_design"><Icon path={mdiStarCircleOutline} size={1.7} /></div>
        <div className="node_info">
            <div className="NodeTitle">Occurrence</div>
            <p className="NodeDesc">Occurrence of Event Vivek</p>
        </div>
        <Handle type="target" position={Position.Top} id="1" />
        <Handle type="source" position={Position.Bottom} id="2" />
    </div>
    </>
}