import React, { useState } from 'react';
import _ from 'lodash';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Sidebar from './Sidebar/Sidebar';
import * as icons from '@mui/icons-material';
import IconButton from '@mui/material/IconButton';
import './Sidebar/Sidebar.css';
import Form1 from './Form/Form1';
import Modal from '@mui/material/Modal';
import { getUpdatedElementsAfterNodeAddition } from './Utils/WorkflowElementUtils.jsx';
import { getIncomers, getOutgoers } from 'react-flow-renderer';
import { initialElements } from './Data/Elements1.jsx';
import Layout from './Automation.js';
import { useNavigate, useLocation } from 'react-router-dom';
import { Button, Typography } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { TemporaryDrawer } from 'views/email-flow/Drawer';

// import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';

const ReactEmailFlow = () => {
    const navigate = useNavigate();
    const navigateToReactFlow = () => {
        // 👇️ navigate to /contacts
        navigate('/');
    };

    const location = useLocation(); // Retrieve the location object
    const stateData = location.state?.selectedItem || null;

    // console.log('listId', listId)
    console.log('stateData', stateData);

    const [elements, setElements] = React.useState([]);

    const onAddNodeCallback = ({ id, type }) => {
        setElements((elements) =>
            getUpdatedElementsAfterNodeAddition({
                elements,
                targetEdgeId: id,
                type,
                onDeleteNodeCallback,
                onNodeClickCallback,
                onAddNodeCallback
            })
        );
    };

    const onDeleteNodeCallback = (id) => {
        setElements((elements) => {
            const clonedElements = _.cloneDeep(elements);
            const incomingEdges = clonedElements.filter((x) => x.target === id);
            const outgoingEdges = clonedElements.filter((x) => x.source === id);
            const updatedIncomingEdges = incomingEdges.map((x) => ({
                ...x,
                target: outgoingEdges[0].target
            }));
            const filteredElements = clonedElements.filter(
                (x) => x.id !== id && x.target !== incomingEdges[0].target && x.source !== outgoingEdges[0].source
            );
            filteredElements.push(...updatedIncomingEdges);
            return filteredElements;
        });
    };

    const onNodeClickCallback = (id) => {
        setElements((elements) => {
            const currentNode = elements.find((x) => x.id === id);
            const nodes = elements.filter((x) => x.position);
            const edges = elements.filter((x) => !x.position);
            console.error({
                incomers: getIncomers(currentNode, nodes, edges),
                outgoers: getOutgoers(currentNode, nodes, edges)
            });
            return elements;
        });
        // alert(`You clicked the "${id}" node`);
        // FormHandlerManagerOpen({ id: id });
    };
    const UpdatedElements = [...(stateData?.rowData?.nodes || []), ...(stateData?.rowData?.edges || [])];

    React.useEffect(() => {
        const nodes = (UpdatedElements.length > 0 ? UpdatedElements : initialElements)
            .filter((x) => !x.target)
            .map((x) => ({
                ...x,
                data: { ...x.data, onDeleteNodeCallback, onNodeClickCallback }
            }));

        const edges = (UpdatedElements.length > 0 ? UpdatedElements : initialElements)
            .filter((x) => x.target)
            .map((x) => ({ ...x, data: { ...x.data, onAddNodeCallback } }));

        setElements([...nodes, ...edges]);
    }, []);

    const Item = styled(Paper)(({ theme }) => ({
        backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
        ...theme.typography.body2,
        padding: theme.spacing(1),
        textAlign: 'center',
        color: theme.palette.text.secondary
    }));
    function GetMaterialIcon({ icon }) {
        const IconComponent = icons[icon];
        return (
            <IconButton>
                <IconComponent />
            </IconButton>
        );
    }

    return (
        <>
            <Box>
                <Grid className="journney_title" container>
                    <Grid item>
                        <Typography variant="h2" sx={{ fontWeight: 500, fontSize: '18px' }}>
                            Campaign Journey
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Box className="journey_title_action">
                            <Button>
                                <AddIcon />
                            </Button>
                        </Box>
                    </Grid>
                </Grid>
            </Box>

            <div className="journey_master">
                <div className="left_container">
                    <Sidebar />
                </div>
                <div className="right_container">
                    {' '}
                    <Layout elements={elements} />
                </div>
                <TemporaryDrawer />
            </div>
        </>
    );
};

export default ReactEmailFlow;
