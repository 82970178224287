import { useState, useEffect } from "react";
import Box from "@mui/material/Box";
// import TextField from "@mui/material/TextField";
import { TextField, Button, Autocomplete, FormControl, InputLabel } from "@mui/material";
import Typography from "@mui/joy/Typography";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import axios from "axios";
import { baseURL } from "config/envConfig";
import { useDispatch, useSelector } from "react-redux";
import { setFormData as setFormDataAction } from "store/action/journeyCanvas";
import { selectGetFormData } from "store/selectors";
export const EmailFormComponent = () => {
  const dispatch = useDispatch();
  const intialFormData = useSelector(selectGetFormData) || {};
  const [options, setOptions] = useState([]);
  const [formData, setFormData] = useState(intialFormData);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    let newState = {
      ...formData,
      [name]: value,
    };
    setFormData(newState);
    dispatch(setFormDataAction(newState));
  };
  // const saveForm = () => {
  //   dispatch(setFormDataAction(formData));
  // };
  useEffect(() => {
    // Fetch options using Axios
    axios
      .get(`${baseURL}/list`)
      .then((response) => {
        const fetchedOptions = response.data;
        setOptions(fetchedOptions);
      })
      .catch((error) => {
        console.error("Error fetching options:", error);
      });
  }, []);
  const [selectedValues, setSelectedValues] = useState([]);
  const handleSelectedValuesChange = (event, newValues) => {
    setSelectedValues(newValues);
  };
  return (
    <Box
      sx={{
        "& .MuiTextField-root": {
          m: 1,
          width: "350px",
        },
        margin: "24px",
      }}
      noValidate
      autoComplete="off"
    >
      <div>
        <Typography level="h4">Email Form </Typography>
      </div>
      <div>
        <TextField
          value={formData.fromName}
          id="filled-hidden-label-normal"
          label="From Name"
          variant="standard"
          onChange={handleInputChange}
          name="fromName"
        />
      </div>
      <div>
        <TextField
          id="filled-hidden-label-normal"
          label="From Domain"
          variant="standard"
          name="fromDomain"
          value={formData.fromDomain}
          onChange={handleInputChange}
        />
      </div>
      <div>
        <FormControl variant="standard" sx={{ m: 1, minWidth: '100%' }}>
          <InputLabel id="demo-simple-select-standard-label">Email List</InputLabel>
          <Select
            labelId="demo-simple-select-standard-label"
            id="demo-simple-select-standard"
            value={formData.offerId}
            name="offerId"
            onChange={handleInputChange}
            label="Email List"
          >
            <MenuItem value="">
              <em>None</em>
            </MenuItem>
            {options.map((option) => (
              <MenuItem key={option.id} value={option.id}>
                {option.listName}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        {/* <Autocomplete
          sx={{
            marginTop: 2,
          }}
          id="clear-on-escape"
          clearOnEscape
          options={options}
          value={selectedValues}
          onChange={handleSelectedValuesChange}
          getOptionLabel={(options) => (options ? options.listName : 'Select List')}

          renderInput={(params) => (
            <TextField {...params} label="values" variant="standard" />
          )}
        /> */}
      </div>
      <div>
        <Box
          sx={{
            marginTop: 4,
            padding: "0px 10px",
            display: "flex",
          }}
        >
          {/* <Button variant="outlined">Save</Button>
          &nbsp; &nbsp; &nbsp;
          <Button variant="outlined">Reset</Button> */}
        </Box>
      </div>
    </Box>
  );
};
